import React from 'react';
import { useState } from 'react';
import {useTelegram} from "../../hooks/useTelegram";
import {Image,Container,Row,Col,Button,Offcanvas} from "react-bootstrap";

const Header = () => {
    const {user} = useTelegram();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <Container className={'pt-2 pb-2'}>
            <Row>
                <Col className={'align-self-center'}><a href={'/'}><img src='/logo.svg' className={"img-fluid p-2"} alt="..."/></a></Col>
                <Col className={'text-end'}>
                <Button variant="light" onClick={handleShow}>
                        <img src={'/icons/burger.svg'}/>
                    </Button>

                    <Offcanvas show={show} onHide={handleClose} placement={'end'} backdrop={'static'}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Меню</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Row>
                                <Col className={'col-3'}><Image className={'img-thumbnail rounded-circle'} src={user?.photo_url}></Image></Col>
                                <Col className={'col-6 align-self-center'}>
                                        <div className={'col'}>@{user?.username}</div>
                                        <div className={'col'}>{user?.first_name}</div>
                                </Col>
                            </Row>

                            <Row className={'mt-2'}>
                                <Col className={'col-2 align-self-center text-center'}><img src={'/icons/store.svg'} /></Col>
                                <Col className={'p-2'}><a className={"link-offset-2 link-body-emphasis link-underline link-underline-opacity-0"} href={"/"}>Магазин</a></Col>
                            </Row>
                            <Row>
                                <Col className={'col-2 align-self-center text-center'}><img src={'/icons/story.svg'}/></Col>
                                <Col className={'p-2'}><a className={"link-offset-2 link-body-emphasis link-underline link-underline-opacity-0"} href={"/story"}>История</a></Col>
                            </Row>
                            <Row>
                                <Col className={'col-2 align-self-center text-center'}><img src={'/icons/user_data.svg'}/></Col>
                                <Col className={'p-2'}><a className={"link-offset-2 link-body-emphasis link-underline link-underline-opacity-0"} href={"/story"}>Ваши данные</a></Col>
                            </Row>
                            <Row>
                                <Col className={'col-2 align-self-center text-center'}><img src={'/icons/about.svg'}/></Col>
                                <Col className={'p-2'}><a className={"link-offset-2 link-body-emphasis link-underline link-underline-opacity-0"} href={"/about"}>О проекте</a></Col>
                            </Row>


                        </Offcanvas.Body>
                    </Offcanvas>
                </Col>
            </Row>
        </Container>
    )
};

export default Header;