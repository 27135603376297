import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import axios from "axios";
import {Col, Container, Image, Row, Button, Carousel} from "react-bootstrap";

const ProductList = () => {

    const [searchParams] = useSearchParams();
    const categoryId = searchParams.get('categoryId');
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        (async () => await Load())();
    }, []);
    useEffect(() => {
        (async () => await Load_categories())();
    }, []);


    async function  Load()
    {
        const result = await axios.get(
            "https://api.gatami.ru/api/products/" + categoryId);
        setProducts(result.data.data);
    }

    async function  Load_categories()
    {
        const result = await axios.get(
            "https://api.gatami.ru/api/categories/" + categoryId);
        setCategories(result.data.data);
    }

    if (products.length > 0) {

        return (
            <Container>


                <Row className="overflow-x-auto flex-nowrap">
                    {categories.map(item => (
                        <Button className={'col m-1'} variant="outline-success">{item.name}</Button>
                    ))}
                </Row>


                {products.map(item => (
                    <Row className={'p-2 align-items-center'}>
                        <Col className={'col-5 align-items-center text-center'}>
                            <Image src={item.image}></Image>
                        </Col>
                        <Col className={'col-7'}>
                            <Row>
                                <Col><b>{item.name}</b></Col>
                            </Row>
                            <Row className={'pt-1'}>
                                <Col>{item.description}</Col>
                            </Row>
                            <Row className={'pt-1'}>
                                <Col>{item.price}</Col>
                            </Row>
                            <Row className={'pt-1'}>
                                <Button variant="outline-success">
                                    Добавить
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                ))}
            </Container>
        );
    }else{
        return (
            <Container>
                В данной категории еще нет товаров
            </Container>
        );
    }
};

export default ProductList;