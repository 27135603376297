import React, {useEffect, useState} from 'react';
import {Container,Row,Col, Figure} from "react-bootstrap";
import axios from 'axios';

const CategoriesList = () => {

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        (async () => await Load())();
    }, []);


    async function  Load()
    {
        const result = await axios.get(
            "https://api.gatami.ru/api/categories/0");
        setCategories(result.data.data);
    }

    if (categories.length > 0){
        return (
            <Container>
                <Row className={'row-cols-3'}>
                    {categories.map(item => (
                        <Col>
                            <a href={'/products?categoryId=' + item.id}>
                                <Figure>
                                    <Figure.Image
                                        src={item.image}
                                    />
                                    <Figure.Caption>
                                        {item.name}
                                    </Figure.Caption>
                                </Figure>
                            </a>
                        </Col>
                    ))}
                < /Row>
            </Container>
        );
    }else{
        return (
            <Container>
                В данной категории еще нет товаров
            </Container>
        );
    }


};

export default CategoriesList;