import './App.css';
import {useCallback, useEffect} from "react";
import {useTelegram} from "./hooks/useTelegram";
import Header from "./components/Header/Header";
import {Route, Routes, useParams } from 'react-router-dom'
import ProductList from "./components/ProductList/ProductList";
import CategoriesList from "./components/CategoriesList/CategoriesList";

function App() {
    const {onToggleButton,tg,user} = useTelegram();

    useEffect(() => {
        tg.ready();
    }, []);

/*        if (user?.id === undefined){
            return (
                <div className="container align-items-center">
                    <div className={'container'}>
                        <img className={'img-thumbnail'} src={'/pic/tg_qr.jpg'}/>

                    </div>
                    <div>
                        <a className={'link-offset-2 link-underline link-underline-opacity-0'}
                           href={'https://t.me/gatami_bot'}>Зайти через телеграмм</a>
                    </div>
                </div>
            );
        } else {*/
            return (
                <div className="App">
                    <Header/>
                    <Routes>
                        <Route index element={<CategoriesList/>}/>
                        <Route path={'products'} element={<ProductList/>}/>
                    </Routes>
                </div>
            );
       // }


}

export default App;
